<template>
  <v-card class="v-card--material-title" :style="styles" v-bind="$attrs" v-on="$listeners">
    <helper-offset v-if="isOffset" :full-width="fullWidth" :offset="os">
      <slot name="offset">
        <div class="offset-template">
          <span style="display: inline-block; height: 36px; line-height: 36px; font-weight: bold">
            {{ titleText }}
          </span>
          <!--<v-chip style="height: 36px;" :label="true" dark color="#666" class="elevation-5">-->
          <!--{{titleText}}-->
          <!--</v-chip>-->
        </div>
      </slot>
    </helper-offset>
    <div :style="textStyles">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
import Card from './Card';

export default {
  name: 'TitleCard',
  inheritAttrs: false,
  props: {
    ...Card.props,
    fullWidth: Boolean,
    offset: {
      type: [Number, String],
      default: 0
    },
    titleText: String
  },
  computed: {
    isOffset() {
      return this.$slots.offset || this.titleText;
    },
    os() {
      return parseInt(this.offset);
    },
    styles() {
      if (!this.os || !this.isOffset) return null;
      return {
        marginTop: `${this.os * 2}px`
      };
    },
    textStyles() {
      if (!this.os || !this.isOffset) return null;
      return {
        marginTop: `-${this.os}px`
      };
    }
  }
};
</script>

<style lang="scss">
.offset-template {
  position: relative;
  &:before {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #666;
    left: -16px;
  }
}
.v-card--material-title {
  position: relative;
  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    padding: 0 16px 0;
  }
}
</style>
