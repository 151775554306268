export default {
  path: '/bmr',
  component: (resolve) => require(['@/views/bmr/index.vue'], resolve),
  redirect: '/bmr/asset/server-report',
  children: [
    {
      path: '/bmr/asset/baremetal-list',
      component: (resolve) => require(['@/views/bmr/asset/baremetal/index.vue'], resolve)
    },
    {
      path: '/bmr/asset/baremetal/:baremetalId',
      component: (resolve) => require(['@/views/bmr/asset/baremetal/BaremetalDetails.vue'], resolve)
    },
    {
      path: '/bmr/asset/server-report',
      component: (resolve) => require(['@/views/bmr/asset/server/index.vue'], resolve)
    },
    {
      path: '/bmr/asset/current',
      component: (resolve) => require(['@/views/bmr/asset/current/index.vue'], resolve)
    },
    {
      path: '/bmr/asset/purchase/purchase-list',
      component: (resolve) => require(['@/views/bmr/asset/purchase/PurchaseList.vue'], resolve)
    },
    {
      path: '/bmr/asset/purchase/:purchaseId',
      redirect: '/bmr/asset/purchase/:purchaseId/particulars',
      component: (resolve) => require(['@/views/bmr/asset/purchase/profile/index.vue'], resolve),
      children: [
        {
          path: '/bmr/asset/purchase/:purchaseId/particulars',
          component: (resolve) =>
            require(['@/views/bmr/asset/purchase/profile/particulars/index.vue'], resolve)
        },
        {
          path: '/bmr/asset/purchase/:purchaseId/inventories',
          component: (resolve) =>
            require(['@/views/bmr/asset/purchase/profile/Inventories.vue'], resolve)
        },
        {
          path: '/bmr/asset/purchase/:purchaseId/activities',
          component: (resolve) =>
            require(['@/views/bmr/asset/purchase/profile/Activities.vue'], resolve)
        }
      ]
    },
    {
      path: '/bmr/asset/inventory/inventory-list',
      component: (resolve) => require(['@/views/bmr/asset/inventory/InventoryList.vue'], resolve)
    },
    {
      path: '/bmr/migration',
      name: 'Data Center Migration',
      component: (resolve) => require(['@/views/bmr/migration/index.vue'], resolve)
    },
    {
      path: '/bmr/devices/routers',
      component: (resolve) => require(['@/views/bmr/devices/routers/routerList.vue'], resolve)
    },
    {
      path: '/bmr/devices/routers/:routerId',
      component: (resolve) => require(['@/views/bmr/devices/routers/routerItems.vue'], resolve)
    },
    {
      path: '/bmr/devices/switches',
      component: (resolve) => require(['@/views/bmr/devices/switches/switchList.vue'], resolve)
    },
    {
      path: '/bmr/devices/switches/:switchId',
      component: (resolve) => require(['@/views/bmr/devices/switches/switchItems.vue'], resolve)
    }
  ]
};
