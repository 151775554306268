import $http from '@/module/http';

let dataCenters = null;
let rentalServers = null;
let rentalDataCenters = null;
let rentalRacks = null;
let gpuModels = null;
let racks = null;
let os = null;
let addonCategories = null;
let motherboardList = null;
let chassisList = null;

import service from '@/store/oss/service';

export default {
  strict: true,
  namespaced: true,
  state: {
    purchaseDetail: {},
    dataCenters: [],
    rentalServers: [],
    rentalDataCenters: [],
    rentalRacks: [],
    racks: [],
    gpuModels: [],
    os: [],
    addonCategories: [],
    motherboardList: [],
    chassisList: []
  },
  mutations: {
    setPurchaseDetail(state, detail) {
      state.purchaseDetail = detail;
    },
    setDataCenters(state, detail) {
      state.dataCenters = detail;
    },
    setRacks(state, obj) {
      state.racks = obj;
    },
    setGpuModels(state, obj) {
      state.gpuModels = obj;
    },
    setOS(state, obj) {
      state.os = obj;
    },
    setAddonCategories(state, obj) {
      state.addonCategories = obj;
    },
    setRental(state, obj) {
      state.rentalServers = obj;
    },
    setRentalDataCenters(state, obj) {
      state.rentalDataCenters = obj;
    },
    setRentalRacks(state, obj) {
      state.rentalRacks = obj;
    },
    setMotherboardList(state, obj) {
      state.motherboardList = obj;
    },
    setChassisList(state, obj) {
      state.chassisList = obj;
    }
  },
  actions: {
    getPurchaseDetailById: ({ commit }, id) => {
      return $http
        .get(`purchases/${id}/`)
        .then((res) => {
          commit('setPurchaseDetail', res);
          return res;
        })
        .catch(() => {});
    },
    getDataCenters: ({ commit }) => {
      if (dataCenters) {
        return dataCenters;
      } else {
        dataCenters = $http
          .get(`data_centers/`)
          .then(({ results }) => {
            commit('setDataCenters', results);
            return results;
          })
          .catch(() => {});
        return dataCenters;
      }
    },
    getRacks: ({ commit }) => {
      if (racks) {
        return racks;
      } else {
        racks = $http
          .get('device_racks/', {
            params: {
              page_size: 999
            }
          })
          .then(({ results }) => {
            commit('setRacks', results);
            return results;
          })
          .catch(() => {});
        return racks;
      }
    },
    getGpuModels: ({ commit }) => {
      if (gpuModels) {
        return gpuModels;
      } else {
        gpuModels = $http
          .get('system_options/?q=key=baremetal_gpu_model')
          .then(({ results }) => {
            commit('setGpuModels', results);
            return results;
          })
          .catch(() => {});
        return gpuModels;
      }
    },
    getOS: ({ commit }) => {
      if (os) {
        return os;
      } else {
        os = $http
          .get('os/?q=status=Active')
          .then(({ results }) => {
            commit('setOS', results);
            return results;
          })
          .catch(() => {});
        return os;
      }
    },
    getAddonCategories: ({ commit }, forceRefresh = false) => {
      if (addonCategories && !forceRefresh) {
        return addonCategories;
      } else {
        addonCategories = $http
          .get('addon_categories/')
          .then(({ results }) => {
            commit('setAddonCategories', results);
            return results;
          })
          .catch(() => {});
        return addonCategories;
      }
    },
    getRentalServers: ({ commit }) => {
      if (rentalServers) {
        return rentalServers;
      } else {
        rentalServers = $http
          .get('rental-servers/', { params: { order_by: 'name' } })
          .then(({ results }) => {
            commit('setRental', results);
            return results;
          })
          .catch(() => {});
        return rentalServers;
      }
    },
    getRentalDataCenters: ({ commit }) => {
      if (rentalDataCenters) {
        return rentalDataCenters;
      } else {
        rentalDataCenters = $http
          .get('rental-data-centers/', { params: { page_size: 999 } })
          .then(({ results }) => {
            commit('setRentalDataCenters', results);
            return results;
          })
          .catch(() => {});
        return rentalDataCenters;
      }
    },
    getRentalRacks: ({ commit }) => {
      if (rentalRacks) {
        return rentalRacks;
      } else {
        rentalRacks = $http
          .get('rental-racks/', { params: { page_size: 999 } })
          .then(({ results }) => {
            commit('setRentalRacks', results);
            return results;
          })
          .catch(() => {});
        return rentalRacks;
      }
    },
    getMotherboardList({ commit }) {
      if (motherboardList) {
        return motherboardList;
      }
      return $http
        .get(`system_options/`, {
          params: {
            page_size: 999,
            page: 1,
            q: `key=baremetal_motherboard_model`
          }
        })
        .then(({ results }) => {
          commit('setMotherboardList', results);
          return results;
        })
        .catch(() => {});
    },
    getChassisList({ commit }) {
      if (chassisList) {
        return chassisList;
      }
      return $http
        .get(`system_options/`, {
          params: {
            page_size: 999,
            page: 1,
            q: `key=baremetal_chassis_model`
          }
        })
        .then(({ results }) => {
          commit('setChassisList', results);
          return results;
        })
        .catch(() => {});
    }
  },
  modules: { service }
};
