var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "material-card",
    _vm._g(
      _vm._b(
        { staticClass: "v-card--material-stats" },
        "material-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        {
          staticClass: "pa-4",
          class: "elevation-" + _vm.elevation + " v-card---material-stats",
          attrs: { slot: "offset", color: _vm.color, dark: "" },
          slot: "offset"
        },
        [
          _c("v-icon", { attrs: { size: "40" } }, [
            _vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "text-right" }, [
        _c("p", {
          staticClass: "body-2 grey--text font-weight-light mb-0",
          domProps: { textContent: _vm._s(_vm.title) }
        }),
        _c("h3", { staticClass: "headline font-weight-light text--primary" }, [
          _vm._v("\n      " + _vm._s(_vm.value) + "\n      "),
          _c("small", [_vm._v(_vm._s(_vm.smallValue))])
        ])
      ]),
      _c("v-divider"),
      _c(
        "template",
        { slot: "actions" },
        [
          _c(
            "v-icon",
            {
              staticClass: "mx-2",
              attrs: { color: _vm.subIconColor, size: "20" }
            },
            [_vm._v("\n      " + _vm._s(_vm.subIcon) + "\n    ")]
          ),
          _c("span", {
            staticClass: "caption font-weight-light",
            class: _vm.subTextColor,
            domProps: { textContent: _vm._s(_vm.subText) }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }