var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "main-panel" },
    [
      _vm.ready
        ? [_c("CoreDrawer"), _c("CoreAppBar"), _c("core-view")]
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.$store.state.topLoading,
            width: "80",
            "no-click-animation": "",
            "content-class": "main-loading",
            persistent: ""
          }
        },
        [
          _c("v-card", { staticStyle: { background: "transparent" } }, [
            _c("img", { attrs: { src: _vm.per } })
          ])
        ],
        1
      ),
      _c("Token", { on: { login: _vm.initPage } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }