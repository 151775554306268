export default {
  strict: true,
  namespaced: true,
  state: {
    staff_token: '',
    user: '',
    userId: '',
    is_online: false
  },
  mutations: {
    setOAuth(state, payload) {
      let keys = Object.keys(payload);
      keys.map((k) => (state[k] = payload[k]));
    }
  }
};
