<template>
  <div :class="{ disabled: disabled }">
    <svg class="fd" aria-hidden="true">
      <template v-if="value">
        <use :xlink:href="`#fd-${value}`"></use>
      </template>
      <template v-else>
        <use :xlink:href="`#fd-${$slots.default[0].text}`">
          <slot></slot>
        </use>
      </template>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DIcon',
  props: ['value', 'disabled']
};
</script>

<style scoped>
.fd {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.disabled {
  color: #999 !important;
  cursor: not-allowed !important;
}
</style>
