export default {
  path: '/dbm',
  component: (resolve) => require(['@/views/dbm/index.vue'], resolve),
  redirect: '/dbm/task/ticket',
  children: [
    {
      path: '/dbm/utilities/dbm-email-task',
      component: (resolve) => require(['@/views/dbm/utilities/DBMEmail/EmailTaskList.vue'], resolve)
    },
    {
      path: '/dbm/utilities/dbm-email-task/:jId',
      component: (resolve) => require(['@/views/dbm/utilities/DBMEmail/Config.vue'], resolve)
    },
    {
      path: '/dbm/utilities/dbm-network-tool',
      component: (resolve) =>
        require(['@/views/dbm/utilities/NetworkTool/NetworkTool.vue'], resolve)
    },
    {
      path: '/dbm/node/dbm-vps-hosting',
      component: (resolve) => require(['@/views/dbm/node/dbm-vps-hosting/HostingList.vue'], resolve)
    },
    {
      path: '/dbm/node/dbm-vps-servers',
      component: (resolve) => require(['@/views/dbm/node/dbm-vps-servers/VPSList.vue'], resolve)
    },
    {
      path: '/dbm/node/dbm-dedicated-servers',
      component: (resolve) => require(['@/views/dbm/node/dbm-dedicated-servers/index.vue'], resolve)
    },
    {
      path: '/dbm/task/list',
      component: (resolve) => require(['@/views/dbm/task/tasklist/index.vue'], resolve)
    },
    {
      path: '/dbm/task/ticket',
      component: (resolve) => require(['@/views/dbm/task/ticketlist/index.vue'], resolve)
    },
    {
      path: '/dbm/task/retention',
      component: (resolve) => require(['@/views/dbm/task/retention/RetentionList.vue'], resolve)
    },
    {
      path: '/dbm/billing/orders',
      component: (resolve) => require(['@/views/dbm/billing/orders/OrderList.vue'], resolve)
    },
    {
      path: '/dbm/billing/suspend',
      component: (resolve) => require(['@/views/dbm/billing/suspend/SuspendList.vue'], resolve)
    },
    {
      path: '/dbm/billing/routine',
      component: (resolve) => require(['@/views/dbm/billing/routine/RoutineResult.vue'], resolve)
    },
    {
      path: '/dbm/billing/cancellation',
      component: (resolve) =>
        require(['@/views/dbm/billing/cancellation/CancellationList.vue'], resolve)
    },
    {
      path: '/dbm/reports/dbm-product-report',
      component: (resolve) => require(['@/views/dbm/reports/products/productsReport.vue'], resolve)
    },
    {
      path: '/dbm/reports/dbm-clients-report',
      component: (resolve) => require(['@/views/dbm/reports/clients/ClientsReport.vue'], resolve)
    },
    {
      path: '/dbm/reports/dbm-packages-report',
      component: (resolve) => require(['@/views/dbm/reports/packages/PackagesReport.vue'], resolve)
    },
    {
      path: '/dbm/reports/dbm-services-report',
      component: (resolve) => require(['@/views/dbm/reports/services/ServicesReport.vue'], resolve)
    },
    {
      path: '/dbm/reports/dbm-affiliate-report',
      component: (resolve) =>
        require(['@/views/dbm/reports/affiliate/AffiliateReport.vue'], resolve)
    }
  ]
};
