import $http from '@/module/http';

let tp, mod, sites, mods;

export default {
  strict: true,
  namespaced: true,
  state: {
    templates: [],
    models: [],
    sites: [],
    mods: []
  },
  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setModels(state, models) {
      state.models = models;
    },
    setSites(state, sites) {
      state.sites = sites;
    },
    setMods(state, mods) {
      state.mods = mods;
    }
  },
  actions: {
    getTemplates: ({ commit }, force = false) => {
      if (!tp || force) {
        tp = $http
          .get('type-struct-templates/', {
            params: {
              page_size: 9999,
              page: 1
            }
          })
          .then(({ results }) => {
            commit('setTemplates', results);
            return Promise.resolve(results);
          });
      }
      return Promise.resolve(tp);
    },
    getModels: ({ commit }, force = false) => {
      if (!mod || force) {
        mod = $http
          .get('content-types/', {
            params: {
              page_size: 9999,
              page: 1
            }
          })
          .then(({ results }) => {
            commit('setModels', results);
            return Promise.resolve(results);
          });
      }
      return Promise.resolve(mod);
    },
    getContentByModelId: (context, ModelId) => {
      return $http
        .get('content-items/', {
          params: {
            q: `content_type=${ModelId}`,
            page_size: 99999,
            page: 1
          }
        })
        .then(({ results }) => {
          return Promise.resolve(results);
        });
    },
    getSites: ({ commit }, force = false) => {
      if (!sites || force) {
        sites = $http
          .get('websites/', {
            params: {
              page_size: 9999,
              page: 1
            }
          })
          .then(({ results }) => {
            commit('setSites', results);
            return Promise.resolve(results);
          });
      }
      return Promise.resolve(sites);
    },
    getMods: ({ commit }, force = false) => {
      if (!mods || force) {
        mods = $http
          .get('content-blocks/', {
            params: {
              page_size: 9999,
              page: 1
            }
          })
          .then(({ results }) => {
            commit('setMods', results);
            return Promise.resolve(results);
          });
      }
      return Promise.resolve(mods);
    }
  }
};
