<template>
  <v-app class="main-panel">
    <template v-if="ready">
      <CoreDrawer></CoreDrawer>
      <CoreAppBar></CoreAppBar>
      <core-view />
    </template>
    <v-dialog
      :value="$store.state.topLoading"
      width="80"
      no-click-animation
      content-class="main-loading"
      persistent>
      <v-card style="background: transparent">
        <img :src="per" />
      </v-card>
    </v-dialog>
    <Token @login="initPage"></Token>
  </v-app>
</template>
<script>
import Token from '@/components/Token.vue';
import per from '@/assets/images/preloader.svg';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    Token,
    CoreAppBar: () => import('@/components/core/AppBar'),
    CoreDrawer: () => import('@/components/core/Drawer'),
    CoreView: () => import('@/components/core/View')
  },
  data() {
    return {
      ready: false
    };
  },
  computed: {
    ...mapState('oss/service', ['cloudNodes'])
  },
  methods: {
    initPage() {
      Promise.all([
        this.getProducts(),
        this.getPlans(),
        this.getClouds(),
        this.getLocations()
      ]).then(() => {
        this.ready = true;
      });
    },
    ...mapActions('app', ['getProducts', 'getPlans', 'getClouds', 'getLocations'])
  },
  created() {
    this.per = per;
  }
};
</script>
<style lang="scss">
.main-panel {
  .main-loading {
    box-shadow: none;
  }
}
</style>
