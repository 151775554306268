import $http from '@/module/http';
import { permission } from '@/permission';
import { chatURL } from '@/module/constant';
// import {permission} from '../../permission'

let getMenusMap = function () {
  return [
    permission['menu.bss'] && {
      text: 'BSS',
      path: '/business',
      title: 'Business Support System',
      menus: [
        {
          text: 'Dashboard',
          path: '/business/dashboard',
          icon: 'mdi-desktop-mac-dashboard'
        },
        {
          text: 'Client',
          path: '/business/client',
          icon: 'mdi-account-group',
          menus: [
            { text: 'Client List', path: '/business/client/client-list' },
            { text: 'Client Verification', path: '/business/client/client-verification' },
            { text: 'Cancellation Requests', path: '/business/client/cancellation-requests' },
            { text: 'FeedBack', path: '/business/client/feedback' },
            { text: 'Prospects List', path: '/business/client/prospects' },
            { text: 'Waitlist Clients', path: '/business/client/waiting' }
          ]
        },
        {
          text: 'Billing',
          path: '/business/billing',
          icon: 'mdi-pencil-box-multiple',
          menus: [
            { text: 'Invoices', path: '/business/billing/billing-invoice' },
            { text: 'PayPal Subscriptions', path: '/business/billing/paypal-subscriptions' },
            { text: 'Taxes', path: '/business/billing/taxes' },
            { text: 'Routine Jobs', path: '/business/billing/billing-routine' },
            { text: 'Trial Configuration', path: '/business/billing/billing-trial' }
          ]
        },
        {
          text: 'Ticket',
          path: '/business/ticket',
          icon: 'mdi-message-text-clock',
          menus: [
            { text: 'Ticket List', path: '/business/ticket/ticket-list' },
            { text: 'Ticket Feedback List', path: '/business/ticket/feedback-list' },
            { text: 'Ticket Report', path: '/business/ticket/ticket-report' },
            { text: 'Agent Ticket Report', path: '/business/ticket/agent-ticket-report' }
          ]
        },
        !!chatURL && {
          text: 'Live Chat',
          href: chatURL + '/chats-manager',
          target: '_blank',
          icon: 'mdi-chat-processing'
        },
        {
          text: 'Task',
          path: '/business/task',
          icon: 'mdi-message-text-clock',
          menus: [{ text: 'Task List', path: '/business/task/task-list' }]
        },
        {
          text: 'Email Tool',
          path: '/business/email-tool',
          icon: 'mdi-email',
          menus: [
            { text: 'Email Templates', path: '/business/email-tool/template' },
            { text: 'Email Tasks', path: '/business/email-tool/task' }
          ]
        },
        {
          text: 'Reports',
          path: '/business/reports',
          icon: 'mdi-table',
          menus: [
            { text: 'Overview', path: '/business/reports/overview' },
            { text: 'Products (New)', path: '/business/reports/products-report' },
            { text: 'Clients Funnel', path: '/business/reports/clients-funnel' },
            { text: 'Clients', path: '/business/reports/clients-report' },
            { text: 'Packages', path: '/business/reports/packages-report' },
            { text: 'Services', path: '/business/reports/services-report' },
            { text: 'Products', path: '/business/reports/product-report' },
            { text: 'Keywords', path: '/business/reports/keywords-report' }
          ]
        },
        {
          text: 'Customer Service',
          path: '/business/customer-service-report',
          icon: 'mdi-table',
          menus: [
            {
              text: 'Workload Report',
              path: '/business/customer-service-report/clients-report/workload'
            },
            {
              text: 'Workload By Agent',
              path: '/business/customer-service-report/clients-report/workload-by-agent'
            }
          ]
        },
        {
          text: 'Affiliate',
          path: '/business/affiliate',
          icon: 'mdi-table',
          menus: [
            { text: 'Affiliate List', path: '/business/affiliate/affiliate-list' },
            { text: 'AFF Order List', path: '/business/affiliate/aff-order-list' },
            { text: 'Withdrawal List', path: '/business/affiliate/withdraw-list' }
          ]
        },
        {
          text: 'Packages',
          path: '/business/packages',
          icon: 'mdi-apps-box',
          menus: [
            { text: 'App Package List', path: '/business/packages/package-list' },
            { text: 'VPS Package List', path: '/business/packages/vps-list' },
            { text: 'Dedicated Server List', path: '/business/packages/servers/server-list' },
            { text: 'Custom Package List', path: '/business/packages/custom-list' },
            { text: 'Domain Package List', path: '/business/packages/domain-list' },
            { text: 'SSL Package List', path: '/business/packages/ssl-list' },
            { text: 'Free DNS List', path: '/business/packages/free-dns-list' },
            { text: 'Additional Software', path: '/business/packages/additional-software-list' },
            { text: 'Additional Hardware', path: '/business/packages/additional-hardware-list' },
            { text: 'Overused VPS List', path: '/business/packages/overused-vps-list' },
            { text: 'Abnormal App List', path: '/business/packages/abnormal-app-list' },
            { text: 'Abnormal Backup List', path: '/business/packages/abnormal-backup-list' },
            { text: 'APP Assist Service List', path: '/business/packages/assist-service-list' },
            { text: 'Cloud Migration List', path: '/business/packages/cloud-migration' },
            { text: 'Cloned Package List', path: '/business/packages/clone-package-list' },
            { text: 'Migration In Node List', path: '/business/packages/migration-node' }
          ]
        },
        {
          text: 'Product Management',
          path: '/business/product-management',
          icon: 'mdi-apps',
          menus: [
            { text: 'Products', path: '/business/product-management/products' },
            { text: 'Plans', path: '/business/product-management/plans' },
            { text: 'Specifications', path: '/business/product-management/specifications' }
          ]
        },
        {
          text: 'Utilities',
          path: '/business/utilities',
          icon: 'mdi-settings-box',
          menus: [
            { text: 'Sales Website List', path: '/business/utilities/sales-website' },
            { text: 'Coupons List', path: '/business/utilities/coupons' }
          ]
        },
        {
          text: 'Settings',
          path: '/business/settings',
          icon: 'mdi-settings-box',
          menus: [
            { text: 'System Parameters', path: '/business/settings/billing-system' },
            { text: 'Tags Management', path: '/business/settings/tag-mg' },
            { text: 'Backup Cycle', path: '/business/settings/backup-cycle' }
          ]
        },
        { text: 'Logs', path: '/business/logs', icon: 'mdi-message-text-clock' }
      ]
    },
    permission['menu.oss'] && {
      text: 'OSS',
      path: '/operation',
      title: 'Operation Support System',
      menus: [
        {
          text: 'Node Management',
          path: '/operation/node',
          icon: 'mdi-server-network',
          menus: [
            { text: 'VPS Hosting Servers', path: '/operation/node/ccs-vps-hosting' },
            { text: 'Dedicated Servers', path: '/operation/node/dedicated-servers' }
          ]
        },
        {
          text: 'Asset Management',
          path: '/operation/asset',
          icon: 'mdi-clipboard-text',
          menus: [
            { text: 'Rental Servers', path: '/operation/asset/rental/rental-servers' },
            { text: 'Domain List', path: '/operation/asset/domain' },
            { text: 'Server Providers', path: '/operation/asset/providers/providers-server' }
          ]
        },
        {
          text: 'Clouds',
          path: '/operation/clouds',
          icon: 'mdi-cloud',
          menus: [
            { text: 'Cloud List', path: '/operation/clouds/cloud-list' },
            { text: 'Cloud Monitor', path: '/operation/clouds/monitor/cloud-pack' }
          ]
        },
        {
          text: 'Deployment Config',
          path: '/operation/deployment',
          icon: 'mdi-database-edit',
          menus: [
            { text: 'App Version Management', path: '/operation/deployment/app-version' },
            { text: 'OS', path: '/operation/deployment/os' },
            { text: 'Software Addons', path: '/operation/deployment/software-addons' }
          ]
        },
        {
          text: 'Service',
          path: '/operation/service',
          icon: 'mdi-server',
          menus: [
            { text: 'Black and White IPs', path: '/operation/service/black-white-list' },
            { text: 'Black and White Processes', path: '/operation/service/black-white-processes' },
            { text: 'Country Rules', path: '/operation/service/banned-country' },
            { text: 'Banned Email Domains', path: '/operation/service/black-white-domain' },
            { text: 'Service Management', path: '/operation/service/service-mng' },
            { text: 'Log Analysis Event', path: '/operation/service/log-analysis' },
            { text: 'Assist Service', path: '/operation/service/assist-service' },
            { text: 'Alerts', path: '/operation/service/alerts' }
          ]
        },
        {
          text: 'CI / CD',
          path: '/operation/cicd',
          icon: 'mdi-folder-network',
          menus: [
            { text: 'Project List', path: '/operation/cicd/project-list' },
            { text: 'Project Group', path: '/operation/cicd/project-group' }
          ]
        },
        { text: 'Members', path: '/operation/members', icon: 'mdi-account-supervisor-circle' },
        {
          text: 'Bare Metal Monitor',
          path: '/operation/monitor',
          icon: 'mdi-monitor',
          menus: [
            { text: 'In Process List', path: '/operation/monitor/process-list' },
            { text: 'Processed List', path: '/operation/monitor/processed-list' },
            { text: 'White List', path: '/operation/monitor/white-list' },
            { text: 'Port Management', path: '/operation/monitor/port-manage' }
          ]
        }
      ]
    },
    permission['menu.cms'] && {
      text: 'CMS',
      title: 'Content Management System',
      path: '/cms',
      menus: [
        {
          text: 'Site Management ',
          path: '/cms/site',
          icon: 'mdi-sitemap',
          menus: [
            { text: 'Site List', path: '/cms/site/list' },
            { text: 'Module List', path: '/cms/site/mods' }
          ]
        },
        {
          text: 'Data Management ',
          path: '/cms/data',
          icon: 'mdi-view-module',
          menus: [
            { text: 'Model & Data', path: '/cms/data/model' },
            { text: 'Templates', path: '/cms/data/template' }
          ]
        },
        { text: 'Image Upload', path: '/cms/upload/image-upload', icon: 'mdi-cloud-upload' }
      ]
    },
    permission['menu.dbm'] && {
      text: 'DBM',
      title: 'DBM System',
      path: '/dbm',
      menus: [
        {
          text: 'Task',
          path: '/dbm/task',
          icon: 'mdi-message-text-clock',
          menus: [
            { text: 'Ticket List', path: '/dbm/task/ticket' },
            { text: 'Task List', path: '/dbm/task/list' },
            { text: 'Retention List', path: '/dbm/task/retention' }
          ]
        },
        {
          text: 'Billing',
          path: '/dbm/billing',
          icon: 'mdi-pencil-box-multiple',
          menus: [
            { text: 'New Orders', path: '/dbm/billing/orders' },
            { text: 'Suspended List', path: '/dbm/billing/suspend' },
            { text: 'Cancellation Request List', path: '/dbm/billing/cancellation' },
            { text: 'Routine Results', path: '/dbm/billing/routine' }
          ]
        },
        {
          text: 'Node Management',
          path: '/dbm/node',
          icon: 'mdi-server-network',
          menus: [
            { text: 'DBM VPS Hosting Servers', path: '/dbm/node/dbm-vps-hosting' },
            { text: 'DBM VPS Servers', path: '/dbm/node/dbm-vps-servers' },
            { text: 'DBM Dedicated Servers', path: '/dbm/node/dbm-dedicated-servers' }
          ]
        },
        {
          text: 'Reports',
          path: '/dbm/reports',
          icon: 'mdi-table',
          menus: [
            { text: 'Clients', path: '/dbm/reports/dbm-clients-report' },
            { text: 'Packages', path: '/dbm/reports/dbm-packages-report' },
            { text: 'Services', path: '/dbm/reports/dbm-services-report' },
            { text: 'Products', path: '/dbm/reports/dbm-product-report' },
            { text: 'Affiliate', path: '/dbm/reports/dbm-affiliate-report' }
          ]
        },
        {
          text: 'Utilities',
          path: '/dbm/utilities',
          icon: 'mdi-settings-box',
          menus: [
            { text: 'DBM Email Tasks', path: '/dbm/utilities/dbm-email-task' },
            { text: 'DBM Network Tool', path: '/dbm/utilities/dbm-network-tool' }
          ]
        }
      ]
    },
    permission['menu.bmr'] && {
      text: 'BMR',
      title: 'BMR System',
      path: '/bmr',
      menus: [
        {
          text: 'Asset Management',
          path: '/bmr/asset',
          icon: 'mdi-clipboard-text',
          menus: [
            { text: 'Devices/Baremetal Servers', path: '/bmr/asset/baremetal-list' },
            { text: 'Server Report', path: '/bmr/asset/server-report' },
            { text: 'Rack Current', path: '/bmr/asset/current' },
            { text: 'Purchase List', path: '/bmr/asset/purchase/purchase-list' },
            { text: 'Inventory  List', path: '/bmr/asset/inventory/inventory-list' }
          ]
        },
        { text: 'Data Center Migration', path: '/bmr/migration', icon: 'mdi-message-text-clock' },
        {
          text: 'Devices Monitor',
          path: '/bmr/devices',
          icon: 'mdi-clipboard-text',
          menus: [
            { text: 'Routers', path: '/bmr/devices/routers' },
            { text: 'Switches', path: '/bmr/devices/switches' }
          ]
        }
      ]
    }
  ].filter((o) => o);
};

export default {
  strict: true,
  namespaced: true,
  state: {
    preProducts: [],
    products: [],
    productLines: [],
    plans: [],
    clouds: [],
    locations: [],
    menusMap: getMenusMap()
  },
  mutations: {
    setProductLines(state, { results }) {
      state.productLines = results;
    },
    setProducts(state, { results }) {
      state.preProducts = results;
      let arr = results.filter((o) => o.status === 'Active');
      state.products = arr;
    },
    setPlans(state, { results }) {
      state.plans = results;
    },
    setClouds(state, { results }) {
      state.clouds = results;
    },
    setLocations(state, { results }) {
      state.locations = results;
    }
  },
  actions: {
    getProductLines: ({ commit }) => {
      let f = `productLines`;
      let s = localStorage.getItem(f);
      s = s && s !== 'undefined' ? s : '';
      s && commit('setProductLines', JSON.parse(s));
      let p = $http
        .get(`product_lines/ `)
        .then((res) => {
          let str = JSON.stringify(res);
          if (str && str !== 'undefined') {
            localStorage.setItem(f, str);
            commit('setProductLines', res);
          }
          return res;
        })
        .catch(() => {});
      return s ? Promise.resolve(JSON.parse(s)) : p;
    },
    getProducts: ({ commit }) => {
      let f = `products`;
      let s = localStorage.getItem(f);
      s = s && s !== 'undefined' ? s : '';
      s && commit('setProducts', JSON.parse(s));
      let p = $http
        .get(`products/?page_size=999`)
        .then((res) => {
          let str = JSON.stringify(res);
          if (str && str !== 'undefined') {
            localStorage.setItem(f, str);
            commit('setProducts', res);
          }
          return res;
        })
        .catch(() => {});
      return s ? Promise.resolve(JSON.parse(s)) : p;
    },
    getPlans: ({ commit }) => {
      let f = `plans`;
      let s = localStorage.getItem(f);
      s = s && s !== 'undefined' ? s : '';
      s && commit('setPlans', JSON.parse(s));
      let p = $http
        .get('plans/?page_size=999')
        .then((res) => {
          let str = JSON.stringify(res);
          if (str && str !== 'undefined') {
            localStorage.setItem(f, str);
            commit('setPlans', res);
          }
          return res;
        })
        .catch(() => {});
      return s ? Promise.resolve(JSON.parse(s)) : p;
    },
    getClouds: ({ commit }) => {
      let f = `clouds`;
      let s = localStorage.getItem(f);
      s = s && s !== 'undefined' ? s : '';
      s && commit('setClouds', JSON.parse(s));
      let p = $http
        .get('clouds/')
        .then((res) => {
          let str = JSON.stringify(res);
          if (str && str !== 'undefined') {
            localStorage.setItem(f, str);
            commit('setClouds', res);
          }
          return res;
        })
        .catch(() => {});
      return s ? Promise.resolve(JSON.parse(s)) : p;
    },
    getLocations: ({ commit }) => {
      let f = `locations`;
      let s = localStorage.getItem(f);
      s = s && s !== 'undefined' ? s : '';
      s && commit('setLocations', JSON.parse(s));
      let p = $http
        .get('cloud_locations/')
        .then((res) => {
          let str = JSON.stringify(res);
          if (str && str !== 'undefined') {
            localStorage.setItem(f, str);
            commit('setLocations', res);
          }
          return res;
        })
        .catch(() => {});
      return s ? Promise.resolve(JSON.parse(s)) : p;
    }
  }
};
