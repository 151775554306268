import cookie from '@/module/utils/cookie';
import { domain } from '@/module/constant';

let login = function () {
  window.location.href = `${domain.SSO}/staff_login/?next=${window.location.href}`;
};

let logout = function () {
  cookie.remove('staff_token');
  // cookie('staff_token', null, {path: "/", domain: window.location.origin})
  window.location.href = `${domain.SSO}/staff_logout/?next=${window.location.href}`;
};

export { login, logout };
