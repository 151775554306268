import Vue from 'vue';
import $http from '@/module/http';

let plugin = {
  install(Vue) {
    Vue.prototype.$http = $http;
  }
};

Vue.use(plugin);
