import $http from '@/module/http';
let info = null;
let cloud = null;

export default {
  strict: true,
  namespaced: true,
  state: {
    serviceInfo: {
      id: ''
    },
    clouds: {}
  },
  actions: {
    getServiceInfo: ({ commit }, { force = false, serviceId }) => {
      if (!force && info) {
        return info;
      }
      info = $http.get(`platform-services/${serviceId}/`).then((res) => {
        commit('setServiceInfo', res);
        return res;
      });
      return info;
    },

    getCloudList({ commit }, force = false) {
      if (!force && cloud) {
        return cloud;
      }
      cloud = $http.get('cloud_monitors/nodes_info/').then((res) => {
        commit('setClouds', res);
        return res;
      });
      return cloud;
    }
  },
  mutations: {
    setServiceInfo(state, payload) {
      state.serviceInfo = payload;
    },
    setClouds(state, payload) {
      state.clouds = payload;
    }
  }
};
