import $http from '@/module/http';
import Vue from 'vue';

export default {
  strict: true,
  namespaced: true,
  state: {
    templates: null
  },
  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    }
  },
  actions: {
    getTemplates: ({ commit, state }, force = false) => {
      if (force || !state.templates) {
        return $http
          .get('task_type_templates/', {
            params: {
              page_size: 9999,
              page: 1
            }
          })
          .then(({ results }) => {
            commit('setTemplates', results);
            return Promise.resolve(results);
          })
          .catch((e) => {
            Vue.prototype.$message(e.detail);
            return Promise.reject(e);
          });
      } else {
        return Promise.resolve(state.templates);
      }
    }
  }
};
