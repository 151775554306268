import Vue from 'vue';
import { formatDate } from '@/module/utils/date';

let plugin = {
  install(Vue) {
    Vue.filter('formatDate', formatDate);
    Vue.prototype.$formatDate = formatDate;
  }
};

Vue.use(plugin);
