import client from '@/store/bss/client';
import $http from '@/module/http';

let pGpuModels = null;
let emailScenes = null;

export default {
  strict: true,
  namespaced: true,
  state: {
    gpuModels: [],
    emailScenes: []
  },
  mutations: {
    setGpuModels(state, { results }) {
      state.gpuModels = results;
    },
    setEmailScenes(state, { results }) {
      state.emailScenes = results;
    }
  },
  getters: {
    billingMonth() {
      return (duration) => {
        switch (duration) {
          case 1:
            return 'month';
          case 3:
            return '3 months';
          case 6:
            return '6 months';
          case 12:
            return 'year';
          case 24:
            return '2 years';
          case 36:
            return '3 years';
          case 48:
            return '4 years';
          case 60:
            return '5 years';
        }
      };
    }
  },
  actions: {
    getGpuModels: ({ commit }) => {
      let p = null;
      if (pGpuModels) {
        p = pGpuModels;
      } else {
        p = $http
          .get('system_options/', {
            params: {
              q: 'key=baremetal_gpu_model,is_active=true'
            }
          })
          .then((res) => {
            commit('setGpuModels', res);
            return res.results;
          })
          .catch(() => {});
        pGpuModels = p;
      }
      return p;
    },
    getEmailScenes: ({ commit }, email_category = '') => {
      let p = null;
      if (emailScenes && !email_category) {
        p = emailScenes;
      } else {
        p = $http
          .get('email_templates/get_templates_scene/?email_category=' + email_category)
          .then((res) => {
            commit('setEmailScenes', res);
            return res;
          })
          .catch(() => {});
        if (!email_category) {
          emailScenes = p;
        }
      }
      return p;
    }
  },
  modules: { client }
};
