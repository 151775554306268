import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
// import 'material-design-icons-iconfont/dist/material-design-icons.css';

let Vuetify = window.Vuetify;
Vue.use(Vuetify);

let primaryColorMap = {
  ccs: '#3c8dbc',
  hwy: '#2245b2',
  dbm: '#4421be'
};
export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: primaryColorMap[process.env.VUE_APP_ACTOR],
        secondary: '#4caf50',
        tertiary: '#495057',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#5cb860',
        warning: '#ffa21a'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  },
  iconfont: 'md'
});
