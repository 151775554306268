var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { disabled: _vm.disabled } }, [
    _c(
      "svg",
      { staticClass: "fd", attrs: { "aria-hidden": "true" } },
      [
        _vm.value
          ? [_c("use", { attrs: { "xlink:href": "#fd-" + _vm.value } })]
          : [
              _c(
                "use",
                {
                  attrs: { "xlink:href": "#fd-" + _vm.$slots.default[0].text }
                },
                [_vm._t("default")],
                2
              )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }