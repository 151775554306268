export default {
  unit: {
    day: 'day | day(s)',
    month: 'm | month | {n} months',
    year: 'year | years | {n} years',
    currency: '${0}',
    currencySign: '$',
    discount: '{0}%off'
  },
  departments: {
    'Support Department': 'Support Department',
    'Operation Department': 'Operation Department',
    'Billing Department': 'Billing Department',
    'Sales Department': 'Sales Department'
  }
};
