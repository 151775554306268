import $http from '@/module/http';

let info = null;
let countries = null;
let tags = null;
let choices = null;
let membership = null;
let racks = null;
export default {
  strict: true,
  namespaced: true,
  state: {
    racks: [],
    clientInfo: {
      id: '',
      credit_info: {}
    },
    tags: [],
    choices: {}
  },
  mutations: {
    setClientInfo(state, payload) {
      state.clientInfo = Object.assign({}, state.clientInfo, payload);
    },
    setTags(state, payload) {
      state.tags = payload;
    },
    setChoices(state, payload) {
      state.choices = payload;
    },
    setRacks(state, payload) {
      state.racks = payload;
    }
  },
  actions: {
    getClientInfo: ({ commit }, { force = false, clientId }) => {
      if (!force && info) {
        return info;
      }
      info = $http.get(`clients/${clientId}/`).then((res) => {
        commit('setClientInfo', res);
        return res;
      });
      return info;
    },
    getCountries: () => {
      if (!countries) {
        countries = $http
          .get(`/countries/`, {
            params: {
              page_size: 300
            }
          })
          .then((res) => {
            return Promise.resolve(res.results);
          });
      }
      return Promise.resolve(countries);
    },
    getTags: ({ commit }, force = false) => {
      if (!tags || force) {
        tags = $http
          .get(`/tags/`, {
            params: {
              page_size: 300
            }
          })
          .then((res) => {
            commit('setTags', res.results);
            return Promise.resolve(res.results);
          });
      }
      return Promise.resolve(tags);
    },
    getDbmEmailChoices: ({ commit }, force = false) => {
      if (!choices || force) {
        choices = $http.get(`client_bulk_mail_tasks/get_dbm_pre_data/`).then((res) => {
          commit('setChoices', res);
          return Promise.resolve(res);
        });
      }
      return Promise.resolve(choices);
    },
    getMemberships: (context, force = false) => {
      if (!membership || force) {
        membership = $http.get(`/memberships/`).then((res) => {
          return Promise.resolve(res.results);
        });
      }
      return membership;
    },
    getRacks: ({ commit }, force = false) => {
      if (!racks || force) {
        racks = $http
          .get(`/device_racks/`)
          .then((res) => {
            commit('setRacks', res.results);
            return Promise.resolve(res.results);
          })
          .catch((e) => {
            return Promise.reject(e);
          });
      }
      return Promise.resolve(racks);
    }
  }
};
