import Vue from 'vue';
import Vuex from 'vuex';

import oAuth from '@/store/oAuth';
import $http from '@/module/http';
import app from '@/store/app';
import bss from '@/store/bss';
import oss from '@/store/oss';
import cms from '@/store/cms';
import dbm from '@/store/dbm';

Vue.use(Vuex);

let agents = null;

export default new Vuex.Store({
  state: {
    departments: [],
    drawer: null,
    topLoading: false,
    innerGroups: []
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    setState(state, data) {
      let payload = data.payload;
      let changeState = (module, state) => {
        if (!module) {
          let keys = Object.keys(payload);
          keys.map((k) => (state[k] = payload[k]));
        } else {
          let arr = module.split('/');
          let keys = Object.keys(payload);
          let st = state;
          if (arr.length === 1) {
            st = state[arr[0]];
          } else {
            for (let i = 0; i < arr.length; i++) {
              st = st[arr[i]];
            }
          }
          keys.map((k) => (st[k] = payload[k]));
        }
      };
      changeState(data.module, state);
    },
    toggleLoading(state, isLoading = false) {
      if (state.topLoading === isLoading) {
        return;
      }
      state.topLoading = isLoading;
    }
  },
  actions: {
    getAgents: () => {
      if (!agents) {
        agents = $http
          .get(`users/?q=is_active=1`, { params: { page_size: 500 } })
          .then(({ results }) => {
            return Promise.resolve(results);
          })
          .catch((e) => {
            Vue.prototype.$message.error({
              content: e.detail,
              duration: 0,
              closable: true
            });
          });
      }
      return Promise.resolve(agents);
    },
    getDepartments: ({ commit }) => {
      return $http
        .get(`departments/`)
        .then(({ results }) => {
          results = results.filter((item) => item.name !== 'Operation Department');
          commit('setState', { payload: { departments: results } });
          return results;
        })
        .catch((e) => {
          Vue.prototype.$message.error({
            content: e.detail,
            duration: 0,
            closable: true
          });
        });
    },
    getInnerGroups: ({ commit }) => {
      return $http
        .get(`inner_groups/`)
        .then(({ results }) => {
          commit('setState', { payload: { innerGroups: results } });
          return results;
        })
        .catch((e) => {
          Vue.prototype.$message.error({
            content: e.detail,
            duration: 0,
            closable: true
          });
        });
    }
  },
  modules: {
    oAuth,
    app,
    bss,
    oss,
    cms,
    dbm
  }
});
