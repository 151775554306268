export default {
  unit: {
    day: '天 | 天',
    month: '月 | 月 | {n}个月',
    year: '年 | 年 | {n}年',
    currency: '￥{0}',
    currencySign: '￥',
    discount: '{0}折'
  },
  departments: {
    'Support Department': '客服团队',
    'Operation Department': '运维团队',
    'Billing Department': '计费团队',
    'Sales Department': '销售团队'
  }
};
