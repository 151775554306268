export default {
  path: '/operation',
  component: (resolve) => require(['@/views/operation/index.vue'], resolve),
  redirect: '/operation/node/ccs-vps-hosting',
  children: [
    {
      path: '/operation/node/ccs-vps-hosting',
      component: (resolve) =>
        require(['@/views/operation/node/ccs-vps-hosting/HostingList.vue'], resolve)
    },
    // {
    //     path : '/operation/node/ccs-vps-servers',
    //     component: resolve => require(['@/views/operation/node/ccs-vps-servers/VPSList.vue'], resolve),
    // },
    // {
    //     path : '/operation/node/ironic-servers',
    //     component: resolve => require(['@/views/operation/node/ironic-servers/index.vue'], resolve),
    // },
    {
      path: '/operation/node/dedicated-servers',
      component: (resolve) =>
        require(['@/views/operation/node/dedicated-servers/index.vue'], resolve)
    },

    {
      path: '/operation/asset/domain',
      component: (resolve) => require(['@/views/operation/asset/domain/index.vue'], resolve)
    },

    {
      path: '/operation/asset/rental/rental-servers',
      component: (resolve) => require(['@/views/operation/asset/rental/RentalServers.vue'], resolve)
    },
    {
      path: '/operation/asset/rental/rental-servers/:rentalId',
      component: (resolve) =>
        require(['@/views/operation/asset/rental/RentalServersDetail.vue'], resolve)
    },
    {
      path: '/operation/asset/providers/providers-server',
      component: (resolve) =>
        require(['@/views/operation/asset/providers/ProvidersServer.vue'], resolve)
    },

    {
      path: '/operation/service/service-mng',
      redirect: '/operation/service/service-mng/service-list',
      component: (resolve) => require(['@/views/operation/service/index.vue'], resolve),
      children: [
        {
          path: '/operation/service/service-mng/service-list',
          component: (resolve) =>
            require(['@/views/operation/service/serviceManagement/ServiceList.vue'], resolve)
        },
        {
          path: '/operation/service/service-mng/:serviceId',
          redirect: '/operation/service/service-mng/:serviceId/service-profile',
          component: (resolve) =>
            require(['@/views/operation/service/serviceManagement/index.vue'], resolve),
          children: [
            {
              path: '/operation/service/service-mng/:serviceId/service-profile',
              component: (resolve) =>
                require(['@/views/operation/service/serviceManagement/ServiceInfo.vue'], resolve)
            },
            {
              path: '/operation/service/service-mng/:serviceId/service-log',
              component: (resolve) =>
                require(['@/views/operation/service/serviceManagement/ServiceLog.vue'], resolve)
            },
            {
              path: '/operation/service/service-mng/:serviceId/service-dependent',
              component: (resolve) =>
                require([
                  '@/views/operation/service/serviceManagement/DependentService.vue'
                ], resolve)
            }
          ]
        }
      ]
    },
    {
      path: '/operation/clouds/cloud-list',
      component: (resolve) => require(['@/views/operation/clouds/cloud/index.vue'], resolve)
    },
    {
      path: '/operation/clouds/monitor/cloud-pack',
      component: (resolve) => require(['@/views/operation/clouds/monitor/CloudPack.vue'], resolve)
    },
    {
      path: '/operation/clouds/monitor/:cloudId/nodelist',
      component: (resolve) => require(['@/views/operation/clouds/monitor/CloudList.vue'], resolve)
    },
    {
      path: '/operation/clouds/monitor/:cloudId/:nodeName/pod-list',
      component: (resolve) => require(['@/views/operation/clouds/monitor/PodList.vue'], resolve)
    },
    {
      path: '/operation/service/log-analysis',
      component: (resolve) =>
        require(['@/views/operation/service/logAnalysis/LogAnalysisList.vue'], resolve)
    },
    {
      path: '/operation/service/black-white-list',
      component: (resolve) => require(['@/views/operation/service/black/BlackList.vue'], resolve)
    },
    {
      path: '/operation/service/black-white-processes',
      component: (resolve) =>
        require(['@/views/operation/service/blackProcess/BlackProcessList.vue'], resolve)
    },
    {
      path: '/operation/service/black-white-Domain',
      component: (resolve) =>
        require(['@/views/operation/service/blackDomain/BlackDomainList.vue'], resolve)
    },
    {
      path: '/operation/service/banned-country',
      component: (resolve) => require(['@/views/operation/service/blackCountry/index.vue'], resolve)
    },
    {
      path: '/operation/deployment/app-version',
      redirect: '/operation/deployment/app-version/version-list',
      component: (resolve) =>
        require(['@/views/operation/deployment/appVersion/index.vue'], resolve),
      children: [
        {
          path: '/operation/deployment/app-version/version-list',
          component: (resolve) =>
            require(['@/views/operation/deployment/appVersion/Versions.vue'], resolve)
        },
        {
          path: '/operation/deployment/app-version/template-list',
          component: (resolve) =>
            require(['@/views/operation/deployment/appVersion/Templates.vue'], resolve)
        },
        {
          path: '/operation/deployment/app-version/mods',
          component: (resolve) =>
            require(['@/views/operation/deployment/appVersion/Mods.vue'], resolve)
        },
        {
          path: '/operation/deployment/app-version/image-upgrade',
          component: (resolve) =>
            require(['@/views/operation/deployment/appVersion/ImageUpgrade.vue'], resolve)
        }
      ]
    },
    {
      path: '/operation/deployment/os',
      component: (resolve) => require(['@/views/operation/deployment/os/index.vue'], resolve)
    },
    {
      path: '/operation/deployment/software-addons',
      component: (resolve) =>
        require(['@/views/operation/deployment/software-addons/index.vue'], resolve),
      redirect: '/operation/deployment/software-addons/version',
      children: [
        {
          path: '/operation/deployment/software-addons/version',
          component: (resolve) =>
            require(['@/views/operation/deployment/software-addons/VersionList.vue'], resolve)
        },
        {
          path: '/operation/deployment/software-addons/component',
          component: (resolve) =>
            require(['@/views/operation/deployment/software-addons/ComponentList.vue'], resolve)
        },
        {
          path: '/operation/deployment/software-addons/software',
          component: (resolve) =>
            require(['@/views/operation/deployment/software-addons/SoftwareList.vue'], resolve)
        },
        {
          path: '/operation/deployment/software-addons/category',
          component: (resolve) =>
            require(['@/views/operation/deployment/software-addons/CategoryList.vue'], resolve)
        }
      ]
    },
    {
      path: '/operation/service/assist-service',
      component: (resolve) =>
        require(['@/views/operation/service/assistService/AssistService.vue'], resolve)
    },
    {
      path: '/operation/service/alerts',
      component: (resolve) => require(['@/views/operation/service/alerts/index.vue'], resolve)
    },
    {
      path: '/operation/cicd/project-list',
      component: (resolve) => require(['@/views/operation/cicd/project/index.vue'], resolve)
    },
    {
      path: '/operation/cicd/project-group',
      component: (resolve) => require(['@/views/operation/cicd/projects/index.vue'], resolve)
    },
    {
      path: '/operation/members',
      component: (resolve) => require(['@/views/operation/members/index.vue'], resolve)
    },
    {
      path: '/operation/monitor/process-list',
      component: (resolve) => require(['@/views/operation/monitor/process-list/index.vue'], resolve)
    },
    {
      path: '/operation/monitor/processed-list',
      component: (resolve) =>
        require(['@/views/operation/monitor/processed-list/index.vue'], resolve)
    },
    {
      path: '/operation/monitor/white-list',
      component: (resolve) => require(['@/views/operation/monitor/white-list/index.vue'], resolve)
    },
    {
      path: '/operation/monitor/port-manage',
      component: (resolve) => require(['@/views/operation/monitor/port-manage/index.vue'], resolve)
    }
  ]
};
