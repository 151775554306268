export default {
  path: '/cms',
  component: (resolve) => require(['@/views/content/index.vue'], resolve),
  redirect: '/cms/site/list',
  children: [
    {
      path: '/cms/site/list',
      component: (resolve) => require(['@/views/content/site/SiteList.vue'], resolve)
    },
    {
      path: '/cms/site/mods',
      component: (resolve) => require(['@/views/content/site/ModList.vue'], resolve)
    },
    {
      path: '/cms/site/site-detail/:sid',
      component: (resolve) => require(['@/views/content/site/SiteDetail.vue'], resolve)
    },
    {
      path: '/cms/data/model',
      component: (resolve) => require(['@/views/content/data/model/index.vue'], resolve),
      redirect: '/cms/data/model/list',
      children: [
        {
          path: '/cms/data/model/list',
          component: (resolve) => require(['@/views/content/data/model/ModelList.vue'], resolve)
        },
        {
          path: '/cms/data/model/:mid',
          component: (resolve) => require(['@/views/content/data/model/DataList.vue'], resolve)
        }
      ]
    },
    {
      path: '/cms/data/template',
      component: (resolve) => require(['@/views/content/data/template/index.vue'], resolve)
    },
    {
      path: '/cms/upload/image-upload',
      component: (resolve) => require(['@/views/content/upload/ImageUpload.vue'], resolve)
    }
  ]
};
